import React from "react";

function ErrorPage() {
    return (
        <div>
            <p>Página 404!Teste</p>
        </div>
    );
}

export default ErrorPage;
